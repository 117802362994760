import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Guide from "../views/Guide.vue";
import Ptgf from "../components/Ptgf.vue";
import Ysxy from "../components/Ysxy.vue";
import yhxy from "../components/yhxy.vue";
import bqsm from "../components/bqsm.vue";
import zxxy from "../components/zxxy.vue";
import hyxy from "../components/hyxy.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/guide",
    name: "guide",
    component: Guide,
  },
  {
    path: "/ptgf",
    name: "ptgf",
    component: Ptgf,
  },
  {
    path: "/ysxy",
    name: "ysxy",
    component: Ysxy,
  },
  {
    path: "/yhxy",
    name: "yhxy",
    component: yhxy,
  },
  {
    path: "/bqsm",
    name: "bqsm",
    component: bqsm,
  },
  {
    path: "/zxxy",
    name: "zxxy",
    component: zxxy,
  },
  {
    path: "/hyxy",
    name: "hyxy",
    component: hyxy,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
