
<template>
  <div class="app">
    <h1>《源调试会员服务协议》</h1>
    <h2>更新日期:2024年1月20日</h2>
    <h2>欢迎您使用源调试会员服务！</h2>
    <h2>
      为使用源调试会员服务（简称为：本服务），请您仔细阅读、理解并遵守《源调试会员服务协议》（简称为：本协议）。
    </h2>
    <h2>
      本协议由您（以下亦称“会员”）和源调试进行缔结，对双方具有同等法律效力。源调试建议您仔细阅读本协议的全部内容，尤其是以加粗形式展示的，与您的权益（可能）存在重大关系的条款，请您留意重点阅读。请您务必在确保您已经理解、接受了本协议条款的前提下，继续使用源调试会员服务。
    </h2>
    <h2>
      如果您不同意本协议的任一或全部条款内容，请不要以接受本服务的形式（包括但不限于支付行为/接受赠与、或完成了成为会员的全部程序而在此过程中未向源调试提出关于本协议的任何异议）进行下一步操作或使用本服务。当您以确认形式进行下一步操作或使用源调试会员服务时，即表示您与源调试已达成协议关系，您自愿接受本协议并遵守本协议项下的全部约定。
    </h2>
    <h2>
      源调试有权变更本协议内容，一旦本协议内容发生变更的，源调试将在相应页面、站内信或以其他合理方式进行通知，请您仔细阅读。如果您不同意变更的内容的，您可以选择停止使用源调试会员服务。如您继续使用源调试会员服务的，则视为您已经同意变更的全部内容。更新后的协议自文首更新之日起生效。
    </h2>
    <h2>若您是未成年人，请您在监护人监护、指导下阅读并决定是否同意本协议。</h2>
    <h2>一、会员权益及额外付费相关</h2>
    <h2>1.【源调试会员】</h2>
    <ul>
      <li>
        是指完成了成为会员的所有程序，且在遵守本协议的前提下，在会员时长有效期内享受源调试提供的会员服务的自然人。
      </li>
      <li>
        VIP会员权益：保存obs直播数据、内容发布、搭建obs直播内容以及包括但不限于收藏、下载、评论、软件内功能应用、平台活动等功能或服务，具体以平台实际提供的为准。同时平台有权对提供的功能或服务进行增加、关停或其他调整。本协议项下的会员权益名称与页面的说明不一致的，不影响您所实际享受的会员权益的内容。
      </li>
      <li>
        源调试仅提供直播搭建、直播数据保存、特效素材、软件内功能应用和资源的使用权，为保证所有上传者的权益，特效素材源文件与直播参数会被加密，任何破解、复制、转发行为不被允许。
      </li>
    </ul>
    <h2>2.【设备及系统差异】</h2>
    <ul>
      <li>
        您成为会员后，可能会因您使用的软件版本、设备、操作系统等不同以及其他第三方原因导致实际可使用的具体权益或服务内容有差别，由此可能给您带来的不便，您表示理解且不予追究或豁免源调试的相关责任。源调试建议您可以通过升级应用程序或操作系统版本、更换使用设备等方式来尝试解决。
      </li>
    </ul>
    <h2>3.【应用内付费】</h2>
    <ul>
      <li>
        VIP会员在会员有效期内可以使用源调试所有VIP会员功能，【素材、图片、配置、插件】中部分内容需要额外付费后方可使用。
      </li>
    </ul>
    <h2>3.1【付费内容】</h2>
    <ul>
      <li>
        签约作者上传的素材、图片、配置、插件，版权归签约作者所有，由签约作者定价销售。该类型的资源需要用户充值源调试金币后购买，在没有vip会员的情况下源调试金币可用于兑换限时体验会员
      </li>
    </ul>
    <h2>4.【活动参与】</h2>
    <ul>
      <li>
        在会员服务期限内，您有权选择参加由源调试组织的会员活动并享有由源调试提供的各项优惠。具体活动及优惠的内容及规则以源调试实际提供为准。
      </li>
    </ul>
    <h2>5.【使用规范】</h2>
    <ul>
      <li>
        您在获取、使用源调试会员服务(包括参与活动)时，应遵循源调试官方渠道公布的流程、平台规范等：
      </li>
      <li>
        5.1不得通过未经源调试授权或公布的途径、方式、渠道非法获取源调试会员服务（包括但不限于部分或全部会员权益、会员账号）；
      </li>
      <li>
        5.2不得通过不正当手段或以违反诚实信用原则的方式（如利用规则漏洞、利用系统漏洞、滥用会员身份、黑色产业、投机等违背源调试提供服务/举办活动的初衷的方式）参与活动或者获取福利。
      </li>
      <li>若您违反上述约定，则源调试有权对您所获得的福利作出删除、取消、清零等处理，且有权终止向您提供服务，由此造成的全部损失由您自行承担。</li>
    </ul>
    <h2>6.【服务期限】</h2>
    <ul>
      <li>6.1 您的会员服务的服务期限以您自行选择并支付相应会员费用对应的服务期限为准，自您成源调试会员之时起算，您可以通过登录源调试个人中心免费查询。该期限不因您未使用而延长。当会员服务到期后，源调试将停止继续向您提供会员服务。</li>
      <li>如您参加活动获得了部分功能免费使用，在该功能存续期间一直享有服务，无会员到期时限。</li>
      <li>6.2 请您理解，因互联网服务的特殊性，会员服务期限中包含源调试解决故障、服务器维修、调整、升级等或因第三方侵权处理所需用的合理时间，但源调试会尽可能将影响降至最低。</li>
    </ul>
    <h2>二、收费及退订</h2>
    <h2>1.【收费方式】</h2>
    <ul>
      <li>源调试会员服务为收费服务，您可通过源调试实际支持的付费方式完成源调试会员费用的支付，如支付宝、微信等。</li>
    </ul>
    <h2>2.【费用退还】</h2>
    <ul>
      <li>源调试会员服务系网络商品和虚拟商品，会员费用是您所购买的会员服务所对应的网络商品价格，而非预付款或者存款、定金、储蓄卡等性质，会员服务一经购买退款需按天扣除服务费。</li>
      <li>VIP会员退款：购买后当天申请退款，扣除5元基础服务费，从购买后第2天起申请退款，每天消耗0.5元服务费，当购买VIP会员时的付费金额全消耗完后，即不可退款。</li>
      <li>源调试金币退款：购买后当天申请退款，扣除5元基础服务费，从购买后第2天起申请退款，每天消耗1元服务费，当购买源调试金币的付费金额全消耗完后，即不可退款。</li>
      <li>注意！限时体验的试用会员不支持退款！</li>
      <li>源调试在此特别提醒您，您在购买会员服务之前应仔细核对账号信息、购买的服务内容、价格、服务期限等信息。</li>
    </ul>
    <h2>3.【收费标准、方式的变更】</h2>
    <h2>源调试会员服务的收费方式、收费标准由源调试根据公司的运营成本、运营策略等综合考虑后独立决定（调整包括但不限于促销、涨价等），并在相关的产品服务宣传及支付页面向您展示；若您在购买和续费时，相关收费方式发生变化的，以源调试实际支持的收费方式为准；相关服务的价格发生了调整的，应以源调试平台上公示的现时有效的价格为准。</h2>
    <h2>三、行为规范和违约处理</h2>
    <h2>1. 在您使用源调试会员服务的过程中，不得存在以下行为：</h2>
    <ul>
      <li>1.1未经源调试明确授权，通过技术手段对服务内容、服务期限、消费金额、交易状态等信息进行修改；</li>
      <li>1.2将会员服务通过非源调试明确授权的方式（包括但不限于借用、租用、售卖、分享、转让）提供他人使用；</li>
      <li>1.3未经源调试明确授权，将会员服务提供的素材、插件、直播参数、图片、音效等进行复制、下载、上传、修改、编目排序、翻译、发行、开发、转让、销售、传播、进行商业开发或推广等；</li>
      <li>1.4对源调试用于保护会员服务的任何安全措施技术进行破解、更改、反操作、篡改或其他破坏；</li>
      <li>1.5未经源调试明确授权，采用收费或免费的方式，在任何公开场合全部或部分展示源调试会员服务内容（但如您的上述行为不构成侵权的除外）；</li>
      <li>1.6其他未经源调试明示授权许可或违反本协议、法律法规或监管政策、侵犯第三方或源调试合法权益的行为。</li>

    </ul>
    <h2>2.您知悉并同意，如您存在任何违反国家法律法规或监管政策、违反本协议或有损源调试或/及其关联公司的声誉、利益的行为的，源调试有权采取以下一项或多项处理措施：</h2>
    <h2>2.1如本协议对此行为有单独条款约定处理方式的，按照该条款处理；</h2>
    <h2>2.2采取一种或多种措施制止您的行为及行为产生的后果，如删除/屏蔽相关链接或内容、限制/取消您的账号/账户使用权限等；</h2>
    <h2>2.3中断或终止部分或全部会员服务，且您已交纳的会员服务费用将不予退还且不获得任何形式的补偿/赔偿；</h2>
    <h2>2.4如您的行为使源调试或/及其关联公司遭受任何损失的，您应当承担全部损失赔偿责任并在源调试要求的时限内完成费用支付。</h2>
    <h2>四、服务的中断和终止 </h2>
    <ul>
      <li>1.本服务的中断或终止包括如下情况：</li>
      <li>1.1您主动提出要求的；</li>
      <li>1.2您存在任何违反国家法律法规或监管政策、违反本协议或有损源调试或/及其关联公司的声誉、利益的行为的；</li>
      <li>1.3源调试根据法律法规、监管政策的规定或有权机关的要求；</li>
      <li>1.4源调试为维护账号与系统安全等紧急情况之需要；</li>
      <li>1.5不可抗力（不可抗力是指：不能预见、不能避免且不能克服的客观情况，包括国家法律、法规、政策及国家机关的命令及其他政府行为或者其它的诸如台风、洪水、地震、海啸、战争、罢工等）；</li>
      <li>1.6其他源调试无法抗拒的情况。</li>
      <li>2.当发生前述终止的情况时，源调试将根据法律法规的规定与您就本服务的中断或终止事宜进行处理。</li>
    </ul>
    <h2>五、您的个人信息保护</h2>
    <ul>
      <li>1.源调试深知个人信息对您的重要性，因此源调试非常重视保护您的个人信息，亦将您的个人信息以高度审慎的义务对待和处理。在您使用源调试会员服务的过程中，源调试将采用相关技术措施及其他安全措施来保护您的个人信息。</li>
      <li>2.未成年人保护</li>
      <li>源调试非常注重未成年人的保护。</li>
      <li>2.1若您为未成年人，应在监护人监护、指导下阅读本协议，并且使用源调试会员服务已经得到监护人的同意。</li>
      <li>2.2监护人应指导子女上网应该注意的安全问题，防患于未然。若监护人同意未成年人使用会员服务，必须以监护人名义申请消费，并对未成年人使用会员服务进行正确引导、监督。未成年人使用会员服务，以及行使和履行本协议项下的权利和义务即视为已获得了监护人的认可。</li>
      <li>2.3源调试提醒未成年人在使用会员服务时，要善于网上学习，认清网络世界与现实世界的区别，避免沉迷于网络，影响日常的学习生活。</li>

    </ul>
    <h2>六、通知 </h2>
    <ul>
      <li>为便于您获知与本协议和会员服务相关的信息，您同意源调试有权通过网页公示、页面提示、弹窗、消息通知、公众号通知、站内信、您预留的联系方式（如手机短信、电子邮件等）等一种或多种方式进行通知，该通知自源调试发送之时视为已成功送达您。如多种通知方式并存的，则送达时间以上述方式中最早发送之时为准。此类通知的内容或将对您产生重大有利或不利影响，请您务必确保联系方式为有效并请及时关注相应通知。</li>
    </ul>
    <h2>七、联系源调试</h2>
    <ul>
      <li>如您对本协议或使用会员服务的过程中有任何问题（包括问题咨询、投诉等），源调试专门为您提供了多种反馈通道，希望为您提供满意的解决方案：</li>
      <li>在线客服/其他在线意见反馈通道：您可与源调试平台上菜单栏页面的在线客服联系或者在线提交意见反馈；</li>
      <li>邮件通道：2257376760@qq.com</li>
      <li>其他方式：源调试提供的其他反馈通道</li>
    </ul>
    <h2>八、其他</h2>
    <ul>
      <li>1.本协议的生效、履行、解释及争议的解决均适用中华人民共和国法律。</li>
      <li>2.如就本协议的签订、履行等发生任何争议的，双方应尽量友好协商解决；协商不成时，任何一方均可向被告住所地享有管辖权的人民法院提起诉讼。</li>
      <li>3.如本协议因与中华人民共和国现行法律相抵触而导致部分无效的，不影响协议的其他部分的效力。</li>
      <li>4.本协议的标题仅为方便及阅读而设，并不影响正文中任何条款的含义或解释。</li>
      <li style="text-align: right;">源调试</li>
      <li style="text-align: right;">武汉源来信息技术有限公司</li>
    </ul>
  </div>
</template>


<script>
export default {
  name: "hyxy", //会员协议
};
</script>



<style lang="scss" scoped>
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 20px;
  font-size: 16px;
}
h2,
h3,
p {
  color: #333;
  font-size: 16px;
  margin-left: 80px;
}
h1 {
  text-align: center;
  font-size: 32px;
}
ul {
  list-style-type: none;
  padding-left: 20px;
  font-size: 16px;
  margin-left: 80px;
}

ul ul {
  padding-left: 40px;
  font-size: 16px;
}
li,
p {
  margin-bottom: 10px;
  font-size: 16px;
  text-indent: 2ch;
}
.app {
  padding-bottom: 30px;
}
</style>