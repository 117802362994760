<template>
  <div class="home">
    <div class="main">
      <div class="left">
        <div class="title">OBS开播如此简单"</div>
        <div class="h2">
          <div>极速</div>
          <img :src="require('../assets/首页图片素材/Frame-1.png')" alt="" />
          <div>流畅</div>
          <img :src="require('../assets/首页图片素材/Frame.png')" alt="" />
          <div>创意</div>
        </div>
        <div class="button" @click="handleOpen">下载源调试</div>
      </div>
      <div class="right">
        <el-image
          :src="require('../assets/首页图片素材/home.png')"
          alt=""
          lazy
        />
      </div>
      <!-- 下载 -->
      <div class="download">
        <div class="downloadList">
          <div
            class="downloadItem"
            v-for="item in ServiceData"
            :key="item.id"
            @click="download(item)"
          >
            <div class="downloadItemLeft">
              <div class="title">{{ item.title }}</div>
              <div class="describe">{{ item.describe }}</div>
              <div class="description">{{ item.description }}</div>
            </div>
            <div class="img">
              <img :src="item.img_src" alt="" />
            </div>
          </div>
          <el-dropdown @command="uploadObs">
            <div class="downloadItem obs">
              <div class="downloadItemLeft">
                <div class="title">OBS</div>
                <div class="describe">点击选择obs下载版本</div>
              </div>
              <div class="img">
                <img
                  src="https://resource.yuantiaoshi.com/officialWebsite/OBS_Studio_Logo.png"
                  alt=""
                />
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="28"
                ><i class="el-icon-download"></i>OBS(28版本)</el-dropdown-item
              >
              <el-dropdown-item command="29"
                ><i class="el-icon-download"></i>OBS(29版本)</el-dropdown-item
              >
              <el-dropdown-item command="30"
                ><i class="el-icon-download"></i>OBS(30版本)</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="briefIntroduction" >
      <div class="title">丰富独特的直播素材</div>
      <div class="title">(一键调试即可实现同款直播效果)</div>
      <div class="enTitle">Rich and unique live broadcast materials</div>
      <div class="enTitle">
        (You can achieve the same live broadcast effect by installing materials with one click)
      </div>
    </div>
    <!-- 图片 -->
    <div class="imgList" >
      <div>
        <el-image
          src="https://resource.yuantiaoshi.com/officialWebsite/home/1.gif"
          lazy
          alt=""
        />
      </div>
      <div class="imgListRight">
        <div class='fz24'>一键调试操作(1/2)</div>
        <div class='fz14'>点击参数设置,选择合适的直播设置,点击确定</div>
      </div>
    </div>
    <!-- 图片 -->
    <div class="imgList" >
      <div class="imgListRight">
        <div class='fz24'>一键调试操作(2/2)</div>
        <div class='fz14'>
          1、选择我的配置。2、选择合适的系统默认配置。3、点
          击obs一键调试后台即会自动导入。
        </div>
      </div>
      <div style="margin-left: 30px">
        <el-image
          src="https://resource.yuantiaoshi.com/officialWebsite/home/2.gif"
          lazy
          alt=""
        />
      </div>
    </div>
    <div class="briefIntroduction" style="background: #191919;">
      <div class="title">丰富独特的直播素材</div>
      <div class="title">(一键安装素材即可实现同款直播效果)</div>
      <div class="enTitle">Rich and unique live broadcast materials</div>
      <div class="enTitle">
        (You can achieve the same live broadcast effect by installing materials
        with one click)
      </div>
    </div>
    <div class="imgList" style="background: #191919;">
      <div>
        <el-image
          src="https://resource.yuantiaoshi.com/officialWebsite/home/3.gif"
          lazy
          alt=""
        />
      </div>
      <div class="imgListRight">
        <div class='fz24'>源调试素材库</div>
        <div class='fz14'>
          源调试素材库不仅有丰富的直播素材，更有多样的图片、
          配置插件、字体等直播效果设置。
          所有效果全部支持一键下载安装应用，便捷更省心。
        </div>
      </div>
    </div>
    <!-- 图片 -->
    <div class="imgList" style="background: #191919;">
      <div class="imgListRight">
        <div class='fz24'>一键安装素材</div>
        <div class='fz14'>支持一键下载安装素材，让开播更简单</div>
      </div>
      <div style="margin-left: 30px">
        <el-image
          src="https://resource.yuantiaoshi.com/officialWebsite/home/4.gif"
          lazy
          alt=""
        />
      </div>
    </div>
    <!-- 图片 -->
    <div class="imgList" style="background: #191919;">
      <div class="imgListRight">
        <div class='fz24'>OBS一键安装应用素材</div>
        <div class='fz14'>
          源调试素材效果一键安装后即可自动导入OBS，在OBS中 可进行多样编辑
        </div>
      </div>
      <div style="margin-left: 30px">
        <el-image
          src="https://resource.yuantiaoshi.com/officialWebsite/home/5.gif"
          lazy
          alt=""
        />
      </div>
    </div>
    <div class="briefIntroduction">
      <div class="title">多样有趣的直播音效</div>
      <div class="title">(自定义上传剪辑音效，支持一键播放)</div>
      <div class="enTitle">
        Various and interesting live broadcast sound effects
      </div>
      <div class="enTitle">
        (custom upload clip sound effects, support one-shot playback)
      </div>
    </div>
    <!-- 音效 -->
    <div class="soundEffects">
      <el-image
        src="https://resource.yuantiaoshi.com/officialWebsite/home/6.gif"
        lazy
        alt=""
      />
      <el-image
        src="https://resource.yuantiaoshi.com/officialWebsite/home/7.gif"
        lazy
        alt=""
      />
      <el-image
        src="https://resource.yuantiaoshi.com/officialWebsite/home/8.gif"
        lazy
        alt=""
      />
    </div>
    <div class="briefIntroduction">
      <div class="title">全方位贴心服务</div>
      <div class="title">随时响应你的需求</div>
      <div class="enTitle">Comprehensive and considerate service</div>
      <div class="enTitle">Response to your needs at any time</div>
    </div>
    <!-- 音效 -->
    <div class="soundEffects">
      <img :src="require('@/assets/首页图片素材/Frame 633.png')" alt="" />
      <img :src="require('@/assets/首页图片素材/Frame 634.png')" alt="" />
      <img :src="require('@/assets/首页图片素材/Frame 635.png')" alt="" />
    </div>
    <div style="height: 140px; width: 100%; background: #000"></div>
    <!-- 备案号 -->
    <div class="recordNumber" @click="go">鄂ICP备2023009234号-1</div>
    <!-- 悬浮 -->
    <!-- <div class="suspension">
      <div class="wx">
        <div>企业微信</div>
        <el-image
          src="https://resource.yuantiaoshi.com/officialWebsite/code.png"
          alt=""
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      ServiceData: [
        {
          id: 1,
          img_src:
            "https://resource.yuantiaoshi.com/officialWebsite/logo.png",
          title: "源调试(最新版)",
          upload: "",
          description: "",
          describe: "告别复杂直播设置 实现高质量直播",
        },
        // {
        //   id: 2,
        //   img_src:
        //     "https://resource.yuantiaoshi.com/officialWebsite/OBS_Studio_Logo.png",
        //   title: "OBS",
        //   description: "28.0.2版本",
        //   upload: "https://resource.yuantiaoshi.com/OBS-Studio-28.0.2-x64.exe",
        //   describe: "用于视频录制和直播的免费开源软件",
        // },
        {
          id: 5,
          img_src:
            "https://p9-arcosite.byteimg.com/tos-cn-i-goo7wpa0wc/57454a8aeb2041a887a149c0674f83d7~tplv-goo7wpa0wc-image.image",
          title: "直播伴侣",
          description: "(直达官网)",
          upload: "https://streamingtool.douyin.com/",
          describe: "抖音直播伴侣",
        },
        // {
        //   id: 4,
        //   img_src:
        //     "https://resource.yuantiaoshi.com/officialWebsite/OBS_Studio_Logo.png",
        //   title: "OBS",
        //   description: "30.1.2版本",
        //   upload: "https://resource.yuantiaoshi.com/OBS%2030.exe",
        //   describe: "用于视频录制和直播的免费开源软件",
        // },
        {
          id: 6,
          img_src: "https://resource.yuantiaoshi.com/officialWebsite/zi.png",
          title: "字体搬运工",
          description: "(直达官网)",
          upload: "https://font.sucai999.com",
          describe: "免费商用字体搬运工",
        },
        // {
        //   id: 3,
        //   img_src:
        //     "https://resource.yuantiaoshi.com/officialWebsite/OBS_Studio_Logo.png",
        //   title: "OBS",
        //   description: "",
        //   upload: "https://resource.yuantiaoshi.com/OBS%2029.exe",
        //   describe: "点击选择obs下载版本",
        // },
      ],
      briefIntroduction: {},
      briefIntroductionList: [],
    };
  },
  async mounted() {
    try {
      await axios.post("/api1/index/visitOfficialWeb");
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async handleOpen() {
      const upload = await axios.get("/api");
      window.open(upload.data.dict_remark);
    },
    uploadObs(type) {
      if (type == 28) {
        window.open(
          "https://resource.yuantiaoshi.com/OBS-Studio-28.0.2-x64.exe"
        );
      } else if (type == 29) {
        window.open(
          "https://resource.yuantiaoshi.com/OBS%2029.exe"
        );
      } else if (type == 30) {
        window.open(
          "https://resource.yuantiaoshi.com/OBS%2030.exe"
        );
      }
    },
    changeTab(type) {
      this.briefIntroduction = this.briefIntroductionList.find(
        (item) => item.id == type
      );
    },
    go() {
      window.open('https://beian.miit.gov.cn/')
    },
    // 下载源调试
    download(item) {
      if (item.id == 1) {
        this.handleOpen();
      } else if (item.id == 3) {
        return;
      } else {
        window.open(item.upload);
      }
    },
  },
};
</script>
<style>
.el-dropdown-menu {
  width: 290px;
}
</style>
<style lang="scss" scoped>
.fz24{
  font-size: 24px;
}
.fz14{
  font-size: 14px;
}
.el-container {
  height: 100%;
}

.home {
  height: calc(100vh - 72px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
.main {
  height: 723px;
  width: 100%;
  background-image: url("../assets/首页图片素材/1.png");
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  .left {
    margin-left: 85px;
    margin-top: 90px;
    .title {
      font-weight: 700;
      font-size: 56px;
      color: #fff;
    }
    .h2 {
      font-size: 36px;
      display: flex;
      align-items: center;
      font-weight: 700;
      margin-top: 12px;
      color: #fff;
      /deep/ img {
        width: 26px;
        height: 26px;
        margin: 0 8px;
      }
      div {
        border-bottom: 1px solid #000;
      }
    }
    .button {
      width: 254px;
      height: 86px;
      background-image: url("../assets/首页图片素材/Button.png");
      background-size: 100% 100%;
      border-radius: 12px;
      text-align: center;
      line-height: 86px;
      font-size: 32px;
      color: #fff;
      font-weight: 700;
      margin-top: 106px;
      cursor: pointer;
      &:hover {
        background-image: url("../assets/首页图片素材/Button-1.png");
      }
    }
    .tip {
      font-size: 16px;
      font-weight: 400;
      margin: 12px 0 0 24px;
    }
  }
  .icon {
    position: absolute;
    width: 68px;
    height: 68px;
    bottom: -34px;
    left: calc(50vw - 34px);
    background: #fff;
    border-radius: 50%;
    box-shadow: 2px 2px 5px #888888;
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/ img {
      width: 34px;
      height: 40px;
    }
  }
  .right {
    width: 500px;
    height: 339px;
    margin-right: 85px;
    margin-top: 90px;
    border-radius: 16px;
    overflow: hidden;
    img {
      width: 500px;
      height: 339px;
    }
  }
}
.download {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 520px;

  .downloadList {
    display: flex;
    justify-content: space-between;
    margin-left: 72px;
    .downloadItem {
      width: 296px;
      height: 128px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 28px;
      box-sizing: border-box;
      background: #191919;
      cursor: pointer;
      color: #fff;
      margin-right: 34px;
      .downloadItemLeft {
        .title {
          font-size: 20px;
        }
        .description {
          font-size: 14px;
        }
        .describe {
          margin-top: 32px;
          font-size: 14px;
        }
      }
      img {
        width: 90px;
        height: 90px;
      }
    }
    .obs {
      margin-right: 0px !important;
    }
  }
}
.briefIntroduction {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000;
  padding-top: 98px;
  padding-bottom: 80px;
  .title {
    font-size: 48px;
    display: inline-block;
    padding-bottom: 10px;
    margin-left: 70px;
    color: #fff;
  }
  .enTitle {
    font-size: 16px;
    margin-top: 4px;
    color: #fff;
    margin-left: 70px;
  }
}
.imgList {
  background: #000;
  padding-bottom: 80px;
  padding-left: 70px;
  color: #fff;
  display: flex;
  /deep/ img {
    height: 450px;
    width: 732px;
  }
  .imgListRight {
    margin-left: 40px;
    padding-top: 150px;
    width: 500px;
    div {
      margin-bottom: 20px;
    }
  }
}

.suspension {
  position: fixed;
  top: 500px;
  left: 65px;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-image: url("../assets/Frame 439.png");
  background-size: 100% 100%;
  box-shadow: 0px 0px 3px #000;
  &:hover {
    .wx {
      display: flex;
    }
  }
  .wx {
    position: fixed;
    top: 450px;
    left: 165px;
    width: 220px;
    height: 160px;
    border-radius: 9px;
    background: #fff;

    flex-direction: column;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px #000;
    display: none;
    div {
      font-size: 18px;
      color: #404565;
    }
    /deep/ img {
      margin-top: 12px;
      width: 100px;
      height: 100px;
    }
  }
}
.recordNumber {
  width: 100%;
  height: 88px;
  text-align: center;
  line-height: 88px;
  font-size: 16px;
  background: #252525;
  color: #fff;
  text-decoration: underline 2px solid #fff;
  cursor: pointer;
}
.soundEffects {
  background: #000;
  display: flex;
  // padding-top: 80px;
  padding-left: 70px;
  img {
    width: 400px;
    height: 280px;
    margin-left: 25px;
  }
  /deep/ .el-image__inner {
    width: 400px;
    height: 560px;
    margin-left: 25px;
  }
}
</style>